<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />


    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>


  </section>
</template>
    
   <!-- #################################################################################### -->
   <!-- ###### Sección de Scripts                                                     ###### -->
   <!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'Billing',
  data: () => ({
    tab: null,
    roles: Role,
    userRoles: {},
    itemsHeader: [
      {
        name: 'Notas crédito',
        link: { name: 'modules.analytics.billing.notasCredito' },
        rol: Role.Analitica_Facturacion_NotasCredito,
      },
      {
        name: 'Devoluciones',
        link: { name: 'modules.analytics.billing.devoluciones' },
        rol: Role.Analitica_Facturacion_Devoluciones,
      },
      {
        name: 'Facturación',
        link: { name: 'modules.analytics.billing.facturacion' },
        rol: Role.Analitica_Facturacion_Facturacion,
      },
    ]
  }),
  components: { BlueHeader }
}
</script>
    
   <!-- #################################################################################### -->
   <!-- ###### Sección de Styles                                                      ###### -->
   <!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>